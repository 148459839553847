import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";

const MainResult = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_course_registration/semesters");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req?.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">ACADEMIC rESULT</p>
                  <div className="row">
                    <div className="col-12">
                      {loading ? (
                        <Loader message="Please wait system loading" />
                      ) : (
                        <>
                          {data &&
                            data?.map((v, i) => (
                              <div key={i} className="pt-3">
                                <div className="d-flex justify-space-arounds align-items-bottom ">
                                  <h1 className="p1topic ">
                                    {v?.code} | {v?.name}
                                  </h1>
                                  <a
                                    href={`/print-result/${v._id}`}
                                    className="no-decoration"
                                  >
                                    <span className="icon-printer text-primary pointer"></span>
                                  </a>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainResult;

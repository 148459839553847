import React from "react";
import { appHost } from "../../helpers/uri";

const OpenReceipt = ({ data }) => {
  const _open = () => {
    window.open(`${appHost}payment-receipt/${data}`, `Student Payment Receipt`, `_parent`, `width=450,height=650`);
  };
  return (
    <div className="d-flex justify-content-center pointer" onClick={_open}>
      <i className="ti-printer" style={{ fontSize: 14 }}></i>
    </div>
  );
};

export default OpenReceipt;

import React from "react";
import { useParams } from "react-router-dom";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";
import is_null from "../../helpers/is_null";
import LetterHeader from "../../components/LetterHeader";
import { contextapi } from "../../helpers/contextapi";

const ModuleForm = () => {
  const context = React.useContext(contextapi);
  const [data, setdata] = React.useState(undefined);
  const [infos, setinfos] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  const [programme, setprogramme] = React.useState(undefined);
  const [levs, setlevs] = React.useState(undefined);
  const [trimester, settrimester] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const { _id: id } = context.values?.student;
        const req = await axiosget(`ms_course_result/student/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { info, prog, levs: lev, tri } = req.data;
        settrimester(tri);
        setinfos(info);
        setlevs(lev);
        setprogramme(prog);
        const reqm = await axiosget("ms_course/student");
        if (reqm.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(reqm.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading ? (
        <Loader
          message={`Please wait while we load your result`}
          img="../images/loading.gif"
        />
      ) : (
        <>
          <>
            {!is_null(data) && data.length > 0 && (
              <div className="resultContainer">
                <LetterHeader />
                <div className="container">
                  <div className="result-wrapper">
                    <div className="student-info">
                      <div className="d-flex">
                        <div className="student-info-label ">Student Name</div>
                        <b className="text-uppercase">
                          {infos.firstname} {infos.middlename} {infos.lastname}
                        </b>
                      </div>
                      <div className="d-flex">
                        <div className="student-info-label">Student ID</div>
                        <b className="text-uppercase">{infos.studentid}</b>
                      </div>
                      <div className="d-flex">
                        <div className="student-info-label">Programme</div>
                        <b className="text-uppercase">{programme?.name}</b>
                      </div>
                      <div className="d-flex">
                        <div className="student-info-label">Trimester </div>
                        <b className="text-uppercase">
                          {trimester?.code} | {trimester?.name}
                        </b>
                      </div>
                      <div className="d-flex">
                        <div className="student-info-label">
                          Level & Semester
                        </div>
                        <b className="text-uppercase">
                          {levs.level} {levs.semester}
                        </b>
                      </div>
                      <div className="d-flex">
                        <div className="student-info-label">Printed Date:</div>
                        <b className="text-uppercase">
                          {new Date().toISOString()}
                        </b>
                      </div>
                    </div>
                    <div>
                      <div className="student-passport">
                        {infos && (
                          <img src={infos?.photo?.fulllink} alt="Student" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="result-wrapper">
                    <table className="styled-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Code</th>
                          <th>Description</th>
                          <th>Assessment</th>
                          <th>Examination</th>
                          <th>Total</th>
                          <th>Credit</th>
                          <th>Point</th>
                          <th>Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((v, i) => (
                          <tr>
                            <th>{i + 1}</th>
                            <td>{v.code}</td>
                            <td>{v.name}</td>
                            <td>{v.cascore}</td>
                            <td>{v.examscore}</td>
                            <td>{v.total}</td>
                            <td>{v.credit}</td>
                            <td>{v.point}</td>
                            <td>{v.grade}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="result-wrapper p-1">
                    <div className="hod_signature text-center">
                      <u>HOD STAMP & DATE</u>
                    </div>
                  </div>
                  <div className="text-center">
                    <i>
                      Temparing with any score/grade on this document makes it
                      invalid, students are advised to leave this statement of
                      result as is.
                    </i>
                  </div>
                </div>
              </div>
            )}
          </>
          <>
            {!is_null(data) && data.length <= 0 && (
              <div className="resultContainer">
                <LetterHeader />
                <div
                  class="alert alert-info alert-dismissible fade show mt-5"
                  role="alert"
                >
                  <strong>Sorry!</strong> Results are not out for the selected
                  semester
                </div>
              </div>
            )}
          </>
        </>
      )}
    </>
  );
};

export default ModuleForm;

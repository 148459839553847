import React from "react";
import SelectStatus from "../../components/SelectStatus";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import ClassSubPosts from "../../components/ClassSubPost";

const MainMaterials = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(undefined);
  const [course, setcourse] = React.useState(undefined);
  const [topics, settopics] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_course/student");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const res = req?.data?.map((v) => ({
          name: `${v.courseinfo.code} | ${v.courseinfo.name}`,
          value: v.courseinfo._id,
        }));
        setdata(res);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!is_null(course)) {
      (async () => {
        try {
          setloading(false);
          const req = await axiosget(`ms_course_topic/my/${course}`);
          if (req.data?.type) {
            throw new Error(req.data?.message);
          }
          const { topics: list } = req.data;
          settopics(list);
        } catch ({ message }) {
          console.warn(message);
        }
        setloading(false);
      })();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">STUDY MATERIAL</p>
                  <div className="row">
                    <div className="col-12">
                      {loading ? (
                        <Loader message="Please wait system loading" />
                      ) : (
                        <>
                          <div className="my-3">
                            <SelectStatus
                              options={data}
                              setitem={setcourse}
                              label="Select Module"
                              active={course}
                            />
                          </div>
                          {topics &&
                            topics?.map((v) => (
                              <div>
                                <div className="d-flex justify-space-arounds align-items-bottom ">
                                  <h1 className="p1topic pt-3">{v?.label}</h1>
                                </div>
                                <div>
                                  {v?.result?.map((v, i) => (
                                    <ClassSubPosts info={v} key={i} />
                                  ))}
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMaterials;

import React from "react";
import { useParams } from "react-router-dom";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";
import PrintHeader from "../../Print/PrintHeader";
import PrintStudentCardProfile from "../../Print/PrintStudentCardProfile";

const PrintRegistration = () => {
  const param = useParams();
  const [loading, setloading] = React.useState(false);
  const [student, setstudent] = React.useState(undefined);
  const [session, setsession] = React.useState(undefined);
  const [programme, setprogramme] = React.useState(undefined);
  const [registration, setregistration] = React.useState(undefined);
  const { id } = param;
  if (is_null(id)) {
    window.close();
  }
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(`ms_course_registration/print-lecturer/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { student: info, session: trimester, programme: prog, registration: reg } = req.data;
        setstudent(info);
        setsession(trimester);
        setprogramme(prog);
        setregistration(reg);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mainPrintContainer">
      {loading ? (
        <Loader />
      ) : (
        <>
          <PrintHeader />
          <>{student && <PrintStudentCardProfile student={student} session={session} programme={programme} title="Module Registration" />}</>
          <>
            {registration && (
              <>
                {registration?.map((v) => (
                  <div className="printStudentRegistration">
                    <div className="semesterInformation">
                      {v.level} {v.semester}
                    </div>
                    <div>
                      <table className="styled-tables">
                        <thead>
                          <tr>
                            <th>SN</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Level</th>
                            <th>Semester</th>
                            <th>Credit</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {v?.register?.map((r, i) => (
                            <tr>
                              <th>{i + 1}</th>
                              <td>{r?.courseinfo?.code}</td>
                              <td>{r?.courseinfo?.name}</td>
                              <td>{r?.level}</td>
                              <td>{r?.semester}</td>
                              <td>{r?.credit}</td>
                              <td>{r.isfresh ? "Fresh" : "Carryover"}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total</td>
                            <td>{v?.register?.map((v) => v.credit).reduce((a, b) => a + b, 0)}</td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
          <div style={{ marginTop: 15, fontSize: 14, padding: 10 }}>
            <div style={{ marginTop: 15, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ textAlign: "center", lineHeight: 2 }}>
                ............................................................................................
                <br />
                Accountant <br />
                Stamped Signature & Date
              </div>
              <div style={{ textAlign: "center", lineHeight: 2 }}>
                ............................................................................................
                <br />
                Programme Coordinator <br />
                Stamped Signature & Date
              </div>
            </div>
            <div style={{ marginTop: 45, textAlign: "center", lineHeight: 2 }}>
              ............................................................................................
              <br />
              Director Undergraduate <br />
              Stamped Signature & Date
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PrintRegistration;

import React from "react";
import Swal from "sweetalert2";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";
import { paymentHandler } from "../../helpers/paymentHandler";
import swal from "../../helpers/swal";
import Loader from "../Loader";

const TuitionPayment = () => {
  const [total, settotal] = React.useState(0);
  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  const [wallet, setwallet] = React.useState(undefined);
  const context = React.useContext(contextapi);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_programme_tuition_fee_items/invoice");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const t = req?.data?.map((v) => v.amount).reduce((a, b) => a + b, 0);

        const reqw = await axiosget("ms_student_wallet");
        if (!is_null(reqw.data?.type)) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
        settotal(t);
        setwallet(reqw.data?.amount);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _pay = async () => {
    try {
      const { student } = context.values;
      const req = await axiosget("ms_session/active");
      const session = req.data;
      const { _id: sessionid } = session;
      const { _id: studentid, emailaddress } = student;
      const payfor = data?.map((v) => v._id);
      const items = payfor.join(",");
      if (
        is_null(sessionid) ||
        is_null(studentid) ||
        is_null(emailaddress) ||
        is_null(items)
      ) {
        throw new Error(
          "Sorry, system detect some of the needed data is missing"
        );
      }
      paymentHandler(
        emailaddress,
        total,
        {
          sessionid,
          studentid,
          items,
          reason: "tuition",
        },
        "/registration"
      );
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
  };
  const _wallet = async () => {
    try {
      const confirm = await Swal.fire({
        title: "CONFIRM WALLET PAYMENT",
        html: `Wallet Balance: <b>${is_money(wallet)}</b><br/>
        Invoice Amount: <b>${is_money(total)}</b><br/>
        New Wallet Balance: <b>${is_money(
          Number(wallet) - Number(total)
        )}</b><br/>
        <div class="text-info">Are you sure you want to pay the sum of <b>${is_money(
          total
        )}</b> from your student wallet.<br/>
        If you confirm this payment the amount of money that will remain in your wallet is: <b>${is_money(
          Number(wallet) - Number(total)
        )}</b></div>`,
        icon: "info",
        padding: "10px",
        confirmButtonColor: "#4b49ac",
        showCancelButton: true,
        confirmButtonText: "Yes I Accept",
      });
      if (!confirm.isConfirmed) {
        setloading(false);
        return;
      }
      const payfor = data?.map((v) => v._id);
      const items = payfor.join(",");
      const req = await axiospost("ms_student_widthdraws", {
        total,
        items,
      });
      if (req.status !== 200 || is_null(req.data?.type)) {
        throw new Error("Sorry, something went wrong, Please try again");
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, undefined, true);
        return;
      }
      swal(type, message, undefined, false);
      return;
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-3">
          <div className="table-responsive mb-3">
            <table className="table table-hover table-bordered" id="example">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((v, i) => (
                  <tr key={v._id}>
                    <th>{i + 1}</th>
                    <th>{v.label}</th>
                    <th>{is_money(v.amount)}</th>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={2}> Total</th>
                  <th>{is_money(total)}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="my-3">
            {wallet >= total && (
              <div className="row">
                <div className="col-md-6">
                  <button
                    onClick={_wallet}
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  >
                    Pay {is_money(total)} from your wallet balance of{" "}
                    {is_money(wallet)}
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    onClick={_pay}
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  >
                    Pay {is_money(total)} to continue registration
                  </button>
                </div>
              </div>
            )}
            {wallet < total && (
              <button
                onClick={_pay}
                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
              >
                Pay {is_money(total)} to continue registration
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TuitionPayment;

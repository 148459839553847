import React from "react";
import ReactModal from "react-modal";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import { contextapi } from "../../helpers/contextapi";
import { walletHandler } from "../../helpers/paymentHandler";

const BtnCreditWallet = () => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [amount, setamount] = React.useState(undefined);

  const [loading, setloading] = React.useState(false);
  const context = React.useContext(contextapi);

  const _save = async () => {
    try {
      const { _id: studentid, emailaddress } = context?.values?.student;
      setloading(true);
      if (is_null(amount) && parseFloat(amount) > 0) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      setloading(false);
      _close();
      walletHandler(emailaddress, amount, { studentid, reason: "wallet" });
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">CREDIT STUDENT WALLET</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">
                  Enter amount you wish to deposit in your wallet (Example 1000
                  for N1,000.00)
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setamount(e.target.value)}
                  placeholder="Enter amount you wish to deposit in your wallet"
                  value={amount}
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Deposit Money
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <div
        style={{
          position: "absolute",
          right: "50px",
          bottom: "50px",
        }}
      >
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnCreditWallet;

import React from "react";
import ViewTrxItems from "../../components/ViewTrxItems";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import moment from "../../helpers/moment";
import Loader from "../../components/Loader";
import OpenReceipt from "./OpenReceipt";

const MainPayments = () => {
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(true);
  const { student } = context.values;
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_transaction/student");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);

  return (
    <div className="main-panel">
      {loading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title text-uppercase">
                      {student.studentid} | {student.firstname}'s Payments
                    </p>
                    <p className="font-weight-500"></p>
                    <div className="d-flex flex-wrap mb-5 table-responsive">
                      <table className="table table-hover table-bordered w-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Transaction Code</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>View Details</th>
                            <th>Print</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((v, i) => (
                              <tr key={i}>
                                <th>{i + 1}</th>
                                <td>{v.rrr}</td>
                                <td>{is_money(v.amount)}</td>
                                <td>{v.status ? "Paid" : "Pending"}</td>
                                <td>
                                  <ViewTrxItems data={v.items} />
                                </td>
                                <td>
                                  <OpenReceipt data={v._id} />
                                </td>
                                <td>{moment(v?.payment?.createdAt)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPayments;

import React from "react";
import "./index.css";
import { appHost } from "../../helpers/uri";

const PrintHeader = () => {
  return (
    <div className="printHeaderContainer">
      <div className="brand-logo text-center">
        <img src={`${appHost}images/logo.jpg`} style={{ maxWidth: 90, paddingRight: 10 }} alt="logo" />
        <img src={`${appHost}images/buk.jpeg`} style={{ maxWidth: 70 }} alt="BUK logo" />
      </div>
      <h1 style={{ fontSize: 25 }}>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h1>
      <h4>In Collaboration with</h4>
      <h1 style={{ fontSize: 25 }}>BAYERO UNIVERSITY KANO</h1>
      <h4>Main Campus Location Along Bauchi-Gombe Road, Bauchi State</h4>
      <h4>info@coekangere.edu.ng | finance@coekangere.edu.ng | support@coekangere.edu.ng</h4>
    </div>
  );
};

export default PrintHeader;

import React from "react";
import "./index.css";

const PrintStudentCardProfile = ({ student, programme, session, title }) => {
  return (
    <div className="printStudentProfile">
      <div>
        <img src={student?.photo?.link} className="PassportPhotograph" alt="profile" />
      </div>
      <div className="ProfileInfo upper">
        <div>
          <div className="muted item">Student ID:</div>
          <div className="itemValue upper">{student.studentid}</div>
        </div>
        <div>
          <div className="muted item">Student Full Name:</div>
          <div className="itemValue upper">
            {student.firstname} {student.middlename} {student.lastname}
          </div>
        </div>
        <div>
          <div className="muted item">Programme:</div>
          <div className="itemValue">
            {programme.code} | {programme.name}
          </div>
        </div>
        <div>
          <div className="muted item">Level & Semester:</div>
          <div className="itemValue">
            {student.level} {student.semester}
          </div>
        </div>
        <div>
          <div className="muted item">Session:</div>
          <div className="itemValue">
            {session.code} | {session.name}
          </div>
        </div>
        <div>
          <div className="muted item">Report Title:</div>
          <div className="itemValue">{title}</div>
        </div>
        <div>
          <div className="muted item">Printed Date:</div>
          <div className="itemValue">{new Date().toISOString()}</div>
        </div>
      </div>
    </div>
  );
};

export default PrintStudentCardProfile;

import React from "react";
import { Link } from "react-router-dom";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import Loader from "../Loader";
//T730917507919118
const VerifyWallet = () => {
  const url = window.location.href.toString().split("=")?.pop();
  const [confirmation, setconfirmation] = React.useState(undefined);
  const [student, setstudent] = React.useState(undefined);
  const [amount, setamount] = React.useState(0);
  const [loading, setloading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_student_wallet_transactions/verify-wallet/${url}`
        );
        if (req.data?.type !== "success") {
          throw new Error(req.data?.message);
        }
        const { trx, info } = req.data;
        setconfirmation(trx);
        setamount(trx.amount);
        setstudent(info);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
        setloading(false);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _print = () => {
    window.print();
  };
  return (
    <div>
      <div className="col-lg-4 mx-auto">
        {loading ? (
          <div className="text-center">
            <Loader />
            <p>Please wait system verifying your payment</p>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img
                    src="images/logo.jpg"
                    className="smaller-logo"
                    alt="logo"
                  />
                </div>
                <div className="text-center">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h6 className="font-weight-light">
                    Wallet Deposit Payment Receipt
                  </h6>
                  <h6 className="font-weight-light">
                    {new Date().toISOString()}
                  </h6>
                </div>
                <div className="pt-3 text-center">
                  <div className="receipt_amount text-center">
                    {is_money(amount)}
                  </div>
                  <div>{url}</div>
                  <div className="text-uppercase">
                    Transaction {confirmation?.status}
                  </div>
                  <div>
                    {student?.firstname} {student?.middlename}{" "}
                    {student?.lastname}
                  </div>
                  <div>{student?.studentid}</div>
                </div>
                <div className="pt-3 no-print">
                  <div className="row">
                    <div className="col-md-6">
                      <Link
                        to="/dashboard"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      >
                        Dashboard
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={_print}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyWallet;

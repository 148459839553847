import React from "react";
import ReactModal from "react-modal";
import is_money from "../../helpers/is_money";
const ViewTrxItems = ({ data }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const total = data?.map((v) => v.amount).reduce((a, b) => a + b, 0);
  return (
    <>
      <ReactModal
        ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">PAYMENT DETAILS</h5>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((v, i) => (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <td>{v.label}</td>
                        <td>{is_money(v.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th>{is_money(total)}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowmodal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <div
        className="d-flex justify-content-center pointer"
        onClick={() => setshowmodal(true)}
      >
        <span className="icon-eye text-primary "></span>
      </div>
    </>
  );
};

export default ViewTrxItems;

import React from "react";
import Navbar from "../../components/Navbar";
import MainWalletTransaction from "../../MainContent/MainWalletTransaction";

const WalletTransaction = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainWalletTransaction />
      </div>
    </div>
  );
};

export default WalletTransaction;

import React from "react";
import { useParams } from "react-router-dom";
import ReceiptHeader from "./ReceiptHeader";
import "./letter.css";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";
import is_null from "../../helpers/is_null";
import is_money from "../../helpers/is_money";
import { QRCode } from "react-qrcode";
import { appHost } from "../../helpers/uri";

const PaymentReceipt = () => {
  const { id } = useParams();
  const [loading, setloading] = React.useState(false);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(`ms_transaction/receipt/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        console.log(req?.data);
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <ReceiptHeader office={`Office of the Bursar`} subs={`Payment receipts`} />
      {loading ? (
        <Loader />
      ) : (
        <div className="container" style={{ marginTop: 10 }}>
          {is_null(data) ? (
            <div>No payment payment information found.</div>
          ) : (
            <div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                  <div className="paymentInfo">
                    Student ID: <b>{!is_null(data) && data?.student?.studentid}</b>
                  </div>
                  <div className="paymentInfo">
                    Full Name:{" "}
                    <b>
                      {!is_null(data) && data?.student?.firstname} {!is_null(data) && data?.student?.middlename}{" "}
                      {!is_null(data) && data?.student?.lastname}
                    </b>
                  </div>
                  <div className="paymentInfo">
                    Remita RRR: <b>{!is_null(data) && data?.rrr}</b>
                  </div>
                  <div className="paymentInfo">
                    Payment Status: <b>{!is_null(data) && data?.status ? "Paid" : "Not paid"}</b>
                  </div>
                  <div className="paymentInfo">
                    Trx. Date: <b>{!is_null(data) && data?.createdAt}</b>
                  </div>
                </div>
                <div>
                  <QRCode value={`${appHost}payment-receipt/${data?._id}`} />
                </div>
              </div>
              <hr />
              <b style={{ textTransform: "uppercase", margin: "10px 0" }}>Payment Items</b>
              <table className="table_component">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>ITEM</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {!is_null(data) &&
                    data?.items?.map((r, i) => (
                      <tr key={r._id}>
                        <th>{i + 1}</th>
                        <td>{r.label}</td>
                        <td>{is_money(r.amount)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td>
                      {is_money(
                        data?.items?.reduce(function (acc, obj) {
                          return acc + obj.amount;
                        }, 0)
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div style={{ marginTop: 45, textAlign: "center" }}>
                Bursary Department <br />
                <small>Sign, Date & Stamp</small>
                <br />
                <small>
                  <i>This document will not be recognized as official without the bursary department signature and stamps it</i>
                </small>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentReceipt;

import PaystackPop from "@paystack/inline-js";
import { axiosget, axiospost } from "./httprequest";
import swal from "./swal";

export const paymentHandler = (email, amount, meta, to) => {
  const handle = PaystackPop.setup({
    key: "pk_test_cbde3c75ddff2292f6cc66cf0041c9cdf91af96f",
    email,
    amount: amount * 100,
    currency: "NGN",
    metadata: meta,
    callback: async (response) => {
      const reference = response.reference;
      const req = await axiosget(`ms_transaction/verify-new/${reference}`);
      if (req.status !== 200) {
        swal(
          "info",
          `Sorry your transaction was successfull, but could not be stored, Please copy this transaction code ${reference} as we will request for it`,
          undefined,
          false
        );
        return;
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, () => (window.location.href = to), false);
        return;
      }
      swal(type, message, undefined, false);
      return;
    },
    onClose: function () {
      swal(
        "info",
        "Transaction was not completed, window closed.",
        undefined,
        false
      );
    },
  });

  handle.openIframe();
};

export const walletHandler = (email, amount, meta) => {
  const handle = PaystackPop.setup({
    key: "pk_test_cbde3c75ddff2292f6cc66cf0041c9cdf91af96f",
    email,
    amount: amount * 100,
    currency: "NGN",
    metadata: meta,
    callback: async (response) => {
      const reference = response.reference;
      const req = await axiosget(
        `ms_student_wallet_transactions/verify-wallet/${reference}`
      );
      if (req.status !== 200) {
        swal(
          "info",
          `Sorry your transaction was successfull, but could not be stored, Please copy this transaction code ${reference} as we will request for it`,
          undefined,
          false
        );
        return;
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, () => window.location.reload(), false);
        return;
      }
      swal(type, message, undefined, false);
      return;
    },
    onClose: function () {
      swal(
        "info",
        "Transaction was not completed, window closed.",
        undefined,
        false
      );
    },
  });

  handle.openIframe();
};

import React from "react";
import Navbar from "../../components/Navbar";
import MainChangeProgramme from "../../MainContent/MainChangeProgramme";

const ChangeProgramme = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainChangeProgramme />
      </div>
    </div>
  );
};

export default ChangeProgramme;

import React, { useEffect, useState } from "react";
// import { Navigate, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import is_null from "./is_null";
import { contextapi } from "./contextapi";

const ProtectedRoute = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [ispermitted, setispermitted] = useState(false);
  // const location = useLocation();
  const isauth = localStorage.getItem("isStudentAuth");
  const context = React.useContext(contextapi);
  useEffect(() => {
    (async () => {
      let login = false;

      if (is_null(isauth)) {
        setispermitted(login);
        setloading(false);
        return;
      } else {
        login = true;
      }

      const { student } = context.values;
      if (is_null(student) && !is_null(localStorage.getItem("isStudent"))) {
        context.setvalues({
          ...context.values,
          student: JSON.parse(localStorage.getItem("isStudent")),
        });
      }
      setispermitted(login);
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!loading ? (
        <>{ispermitted ? children : <Navigate to="/" />}</>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProtectedRoute;

import React from "react";
import { useNavigate } from "react-router-dom";
// import { contextapi } from "../../helpers/contextapi";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const ChangePassword = () => {
  const [loading, setloading] = React.useState(false);
  const [shownew, setshownew] = React.useState(false);
  const [showconfirm, setshowconfirm] = React.useState(false);
  const username = React.useRef();
  const password = React.useRef();
  // const context = React.useContext(contextapi);
  const navigate = useNavigate();

  const _login = async () => {
    try {
      setloading(true);
      const uname = username.current.value;
      const upass = password.current.value;
      if (is_null(uname) || is_null(upass)) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      if (uname !== upass) {
        swal(
          "warning",
          "Please make sure the two passwords are same",
          setloading,
          false
        );
        return;
      }
      const req = await axiospost("ms_students/change", {
        password: upass,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data.type === "success") {
        swal("success", data.message, () => navigate("/"), false);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("warning", message, setloading, false);
    }
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-8 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img src="images/logo.jpg" alt="logo" />
                  <img
                    src="images/buk.jpeg"
                    style={{ maxWidth: 100 }}
                    alt="BUK logo"
                  />
                </div>
                <div className="text-center">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h6 className="font-weight-light">Student Portal</h6>
                  <h6 className="font-weight-light">Change Account Password</h6>
                </div>
                <div className="pt-3">
                  <div className="form-group">
                    <label htmlFor="newpassword">New Password</label>
                    <div className="input-group">
                      <input
                        type={shownew ? "text" : "password"}
                        className="form-control"
                        id="newpassword"
                        placeholder="New Password"
                        aria-label="Recipient's username"
                        ref={username}
                      />
                      <div className="input-group-append ">
                        <button
                          class={
                            shownew
                              ? "btn btn-sm btn-primary w-30"
                              : "btn btn-sm btn-dark w-30"
                          }
                          onClick={() => setshownew(!shownew)}
                          type="button"
                        >
                          <i className="ti-eye"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmpassword">
                      Confirm New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showconfirm ? "text" : "password"}
                        className="form-control"
                        id="confirmpassword"
                        placeholder="Confirm New Password"
                        aria-label="Recipient's username"
                        ref={password}
                      />
                      <div className="input-group-append ">
                        <button
                          class={
                            showconfirm
                              ? "btn btn-sm btn-primary w-30"
                              : "btn btn-sm btn-dark w-30"
                          }
                          onClick={() => setshowconfirm(!showconfirm)}
                          type="button"
                        >
                          <i className="ti-eye"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {loading ? (
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                        Please wait...
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={_login}
                      >
                        Change Password
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;

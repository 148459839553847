import React from "react";
import Navbar from "../../components/Navbar";
import MainMaterials from "../../MainContent/MainMaterials";

const Materials = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainMaterials />
      </div>
    </div>
  );
};

export default Materials;

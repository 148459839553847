import React from "react";
import Navbar from "../../components/Navbar";
import MainAttendance from "../../MainContent/MainAttendance";

const Attendance = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainAttendance />
      </div>
    </div>
  );
};

export default Attendance;

import React from "react";
import Navbar from "../../components/Navbar";
import MainRegisteredCourses from "../../MainContent/MainRegisteredCourses";

const Registered = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainRegisteredCourses />
      </div>
    </div>
  );
};

export default Registered;

import React from "react";

const ClassSubPosts = ({ info }) => {
  const [showfull, setshowfull] = React.useState(false);
  return (
    <>
      <div className="pt-3 pr-3 pl-3 pb-1 mb-2 d-flex justify-space-around align-items-bottom flex-grow flex-row">
        <div>{info.label}</div>
        <div className="d-flex pr-1">
          {info.icon === "paper" && (
            <>
              Post &nbsp;<span className="icon-paper text-primary"></span>
            </>
          )}
          {info.icon === "book" && (
            <a
              href={info?.file?.fulllink}
              className="no-decoration text-primary"
              target="_blank"
              rel="noreferrer"
            >
              Document &nbsp;<span className="icon-book"></span>
            </a>
          )}
        </div>
      </div>
      <span
        dangerouslySetInnerHTML={{
          __html: !showfull
            ? String(info.description)
                .replace(/\n/g, "<br /><br />")
                .substring(0, 300) + "..."
            : String(info.description).replace(/\n/g, "<br /><br />"),
        }}
      />
      {!showfull && (
        <a
          href="/#"
          className="show-more-link"
          onClick={() => setshowfull(true)}
        >
          Read more
        </a>
      )}
      {showfull && (
        <a
          href="/#"
          className="show-more-link"
          onClick={() => setshowfull(false)}
        >
          Read less
        </a>
      )}
    </>
  );
};

export default ClassSubPosts;

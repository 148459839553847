import React from "react";
import is_null from "../../helpers/is_null";
import "./index.css";
import { appHost } from "../../helpers/uri";

const Loader = ({ img, message }) => {
  return (
    <div className="LoaderContainer">
      <img src={is_null(img) ? `${appHost}images/loading.gif` : img} alt="loader" />
      <br />
      {message && <p>{message}</p>}
    </div>
  );
};

export default Loader;

import React from "react";
import { axiosget } from "../../helpers/httprequest";

const LoadAttendance = ({ course, session }) => {
  const [data, setdata] = React.useState(0);
  const [loading, setloading] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_course_attendance/student/${course}/${session}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <td>{loading ? "Please wait..." : data?.expected}</td>
      <td>{loading ? "Please wait..." : data?.total}</td>
      <td>{loading ? "Please wait..." : data?.attended}%</td>
    </>
  );
};

export default LoadAttendance;

import React from "react";
import { contextapi } from "../../helpers/contextapi";

const MainDashboard = () => {
  const context = React.useContext(contextapi);
  const { student } = context.values;
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="row">
                <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                  <h3 className="font-weight-bold">Welcome {student?.firstname}...</h3>
                  <h6 className="font-weight-normal mb-0">
                    Your last login was on <span className="text-primary">{new Date().toISOString()}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card tale-bg">
                <div className="card-people mt-auto">
                  <img src="images/dashboard/people.svg" alt="people" />
                  <div className="weather-info">
                    <div className="d-flex">
                      <div>
                        <h2 className="mb-0 font-weight-normal">
                          <i className="icon-sun mr-2"></i>31<sup>C</sup>
                        </h2>
                      </div>
                      <div className="ml-2">
                        <h4 className="location font-weight-normal">Bauchi</h4>
                        <h6 className="font-weight-normal">Nigeria</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 grid-margin transparent">
              <div className="row">
                <div className="col-md-6 mb-4 stretch-card transparent">
                  <div className="card card-tale">
                    <div className="card-body">
                      <p className="mb-4">Today’s Bookings</p>
                      <p className="fs-30 mb-2">4006</p>
                      <p>10.00% (30 days)</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4 stretch-card transparent">
                  <div className="card card-dark-blue">
                    <div className="card-body">
                      <p className="mb-4">Total Bookings</p>
                      <p className="fs-30 mb-2">61344</p>
                      <p>22.00% (30 days)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                  <div className="card card-light-blue">
                    <div className="card-body">
                      <p className="mb-4">Number of Meetings</p>
                      <p className="fs-30 mb-2">34040</p>
                      <p>2.00% (30 days)</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 stretch-card transparent">
                  <div className="card card-light-danger">
                    <div className="card-body">
                      <p className="mb-4">Number of Clients</p>
                      <p className="fs-30 mb-2">47033</p>
                      <p>0.22% (30 days)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="row">
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">Order Details</p>
                  <p className="font-weight-500">
                    The total number of sessions within the date range. It is
                    the period time a user is actively engaged with your
                    website, page or app, etc
                  </p>
                  <div className="d-flex flex-wrap mb-5">
                    <div className="mr-5 mt-3">
                      <p className="text-muted">Order value</p>
                      <h3 className="text-primary fs-30 font-weight-medium">
                        12.3k
                      </h3>
                    </div>
                    <div className="mr-5 mt-3">
                      <p className="text-muted">Orders</p>
                      <h3 className="text-primary fs-30 font-weight-medium">
                        14k
                      </h3>
                    </div>
                    <div className="mr-5 mt-3">
                      <p className="text-muted">Users</p>
                      <h3 className="text-primary fs-30 font-weight-medium">
                        71.56%
                      </h3>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">Downloads</p>
                      <h3 className="text-primary fs-30 font-weight-medium">
                        34040
                      </h3>
                    </div>
                  </div>
                  <canvas id="order-chart"></canvas>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p className="card-title">Sales Report</p>
                  </div>
                  <p className="font-weight-500">
                    The total number of sessions within the date range. It is
                    the period time a user is actively engaged with your
                    website, page or app, etc
                  </p>
                  <div
                    id="sales-legend"
                    className="chartjs-legend mt-4 mb-2"
                  ></div>
                  <canvas id="sales-chart"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">Advanced Table</p>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table
                          id="example"
                          className="display expandable-table w-100"
                        >
                          <thead>
                            <tr>
                              <th>Quote#</th>
                              <th>Product</th>
                              <th>Business type</th>
                              <th>Policy holder</th>
                              <th>Premium</th>
                              <th>Status</th>
                              <th>Updated at</th>
                              <th></th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;

import React from "react";
import { Link } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";

const Navbar = () => {
  const { innerWidth: width } = window;
  const context = React.useContext(contextapi);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_notification/student");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
        context.setvalues({ ...context.values, notification: req.data });
      } catch ({ message }) {
        console.warn(message);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/dashboard">
          COE KANGERE [UG]
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-menu"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className={`navbar-nav ${width < 600 ? "mr-auto" : "ml-auto"}`}>
            <li className="nav-item">
              <Link className="nav-link" to="/materials">
                Study Materials
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/notifications">
                Notifications{` `}
                {data && data?.length > 0 && <span className="badge badge-pill badge-primary navbar-badge">{data.length}</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/registration">
                Registration
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                My Finance
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/payment">
                  My Payments
                </Link>
                <Link className="dropdown-item" to="/wallet-transaction">
                  My Wallet Transactions
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="AcademicDropDown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Academic Progress
              </a>
              <div className="dropdown-menu" aria-labelledby="AcademicDropDown">
                <Link className="dropdown-item" to="/change-programme">
                  Change of Programme
                </Link>
                <Link className="dropdown-item" to="/attendance">
                  My Attendance
                </Link>
                <Link className="dropdown-item" to="/registered-modules">
                  My Registered Modules
                </Link>
                <Link className="dropdown-item" to="/result">
                  My Academic Results
                </Link>
                <Link className="dropdown-item" to="/progression">
                  My Progression
                </Link>
                <Link className="dropdown-item" to="/module-form">
                  My Module Form
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

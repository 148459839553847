import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { contextapi } from "./helpers/contextapi";
import Modal from "react-modal";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";
import Recover from "./pages/Recover";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import Payment from "./pages/Payment";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Registration from "./pages/Registration";
import Results from "./pages/Results";
import Attendance from "./pages/Attendance";
import Materials from "./pages/Materials";
import NewFee from "./pages/NewFee";
import VerifyPayment from "./components/VerifyPayment";
import NewCourse from "./pages/NewCourse";
import VerifyWallet from "./components/VerifyWallet";
import WalletTransaction from "./pages/WalletTransaction";
import Registered from "./pages/Registered";
import Notifications from "./pages/Notifications";
import PrintResult from "./pages/PrintResult";
import ChangeProgramme from "./pages/ChangeProgramme";
import ModuleForm from "./pages/ModuleForm";
import PrintRegistration from "./pages/PrintRegistration";
import PaymentReceipt from "./pages/PaymentReceipts";

const App = () => {
  const [values, setvalues] = React.useState([]);
  Modal.setAppElement("#root");

  return (
    <React.StrictMode>
      <contextapi.Provider value={{ values, setvalues }}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/forgot" element={<Recover />} />
            <Route exact path="/verify-payment" element={<VerifyPayment />} />
            <Route exact path="/verify-wallet" element={<VerifyWallet />} />
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/module-form"
              element={
                <ProtectedRoute>
                  <ModuleForm />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/wallet-transaction"
              element={
                <ProtectedRoute>
                  <WalletTransaction />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/registered-modules"
              element={
                <ProtectedRoute>
                  <Registered />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/payment"
              element={
                <ProtectedRoute>
                  <Payment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/payment-receipt/:id"
              element={
                <ProtectedRoute>
                  <PaymentReceipt />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/new-fee"
              element={
                <ProtectedRoute>
                  <NewFee />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/new-registration"
              element={
                <ProtectedRoute>
                  <NewCourse />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/materials"
              element={
                <ProtectedRoute>
                  <Materials />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/change"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/registration"
              element={
                <ProtectedRoute>
                  <Registration />
                </ProtectedRoute>
              }
            />
            <Route exact path="/print-registration/:id" element={<PrintRegistration />} />
            <Route
              exact
              path="/attendance"
              element={
                <ProtectedRoute>
                  <Attendance />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/result"
              element={
                <ProtectedRoute>
                  <Results />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/change-programme"
              element={
                <ProtectedRoute>
                  <ChangeProgramme />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/print-result/:id"
              element={
                <ProtectedRoute>
                  <PrintResult />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </contextapi.Provider>
    </React.StrictMode>
  );
};

export default App;

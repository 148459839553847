import React from "react";
import BtnCreditWallet from "../../button/CreditWallet";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";
import moment from "../../helpers/moment";

const MainWalletTransaction = () => {
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(true);
  const { student } = context.values;
  console.log(student);
  const [data, setdata] = React.useState(undefined);
  const [wallet, setwallet] = React.useState(0);
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget(
          `ms_student_wallet_transactions/student/${student._id}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { find, wallet } = req.data;
        setdata(find);
        const account = !is_null(wallet?.amount) ? wallet.amount : 0;
        setwallet(account);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-panel">
      {loading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title text-uppercase">
                      <p className="">{student.studentid}</p>
                      <p className="">
                        {student.firstname} {student.middlename}{" "}
                        {student.lastname}
                      </p>
                      <p className="">Available Wallet Balance</p>
                      <div className="receipt_amount">{is_money(wallet)}</div>
                    </p>

                    <div className="d-flex flex-wrap mb-5 table-responsive">
                      <table className="table table-hover table-bordered w-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Transaction Code</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Transaction Type</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((v, i) => (
                              <tr>
                                <th>{i + 1}</th>
                                <td>{v.trx}</td>
                                <td>{is_money(v.amount)}</td>
                                <td className="text-capitalize">{v.status}</td>
                                <td>{v.trxtype ? "Deposit" : "Widthdrawal"}</td>
                                <td className="text-capitalize">
                                  {moment(v.payment.created_at)} ago
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BtnCreditWallet />
        </div>
      )}
    </div>
  );
};

export default MainWalletTransaction;

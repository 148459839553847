import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

//
const NewCourse = () => {
  const [loading, setloading] = React.useState(false);
  const [total, settotal] = React.useState(0);
  const [list, setlist] = React.useState([]);

  const context = React.useContext(contextapi);
  const navigate = useNavigate();

  const { student: user } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        const items = await axiosget(`ms_programme/new`);
        if (items.status !== 200) {
          throw new Error("Sorry something happened, Please try again later");
        }
        if (items.data.error) {
          throw new Error("Sorry something happened, Please try again later");
        }
        setlist(items.data);
        const sum = items.data?.map((v) => v.credit).reduce((a, b) => a + b, 0);
        settotal(sum);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  const _register = async () => {
    try {
      setloading(true);
      const err = "Sorry something happened, Please try again later";
      const req = await axiospost("ms_course_registration/new");
      if (req.status !== 200) {
        throw new Error(err);
      }
      if (req.data.error) {
        console.warn(req.data.message);
        throw new Error(err);
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, () => navigate("/dashboard"), true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-6 mx-auto">
              <div className="auth-form-light text-left py-1 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img src="images/logo.jpg" alt="logo" />
                </div>
                <div className="text-center">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h5>Modules Registration</h5>
                  <h4>
                    NAMES: {String(user.firstname).toLocaleUpperCase()} {user.middlename} {user.lastname}
                  </h4>
                  <h4>STUDENT ID: {user.studentid}</h4>
                  <Link to={"/dashboard"}>go to Dashboard</Link>
                </div>
                <div className="pt-3">
                  {loading ? (
                    <div className="text-center py-5">
                      <Loader />
                      <p>Please wait system loading your modules</p>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <div className="table-responsive mb-3">
                        <table className="table table-hover table-bordered" id="example">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Code</th>
                              <th>Description</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.length &&
                              list?.map((v, i) => (
                                <tr key={v._id}>
                                  <th>{i + 1}</th>
                                  <th>{v.code}</th>
                                  <th>{v.name}</th>
                                  <th>{v.credit}</th>
                                </tr>
                              ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colSpan={3}> Total</th>
                              <th>{total}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="my-3">
                        <button onClick={_register} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                          Register Modules
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCourse;

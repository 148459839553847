import Swal from "sweetalert2";

const swal = async (icon, text, callback, is_reload) => {
  await Swal.fire({
    html: text,
    icon,
    confirmButtonText: "Ok, got it",
    allowOutsideClick: false,
  }).then((r) => {
    if (callback !== undefined) {
      callback(false);
    }
    if (is_reload) {
      window.location.reload();
    }
  });
};
export default swal;

import React from "react";
import Navbar from "../../components/Navbar";
import MainRegistration from "../../MainContent/MainRegistration";

const Registration = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainRegistration />
      </div>
    </div>
  );
};

export default Registration;

import React from "react";
import swal from "../../helpers/swal";
import { axiosget, axiospost } from "../../helpers/httprequest";
import RegisteredCourse from "../RegisteredCourse";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";
import Loader from "../Loader";
import Swal from "sweetalert2";
import toastr from "toastr";

const CourseRegistration = () => {
  const context = React.useContext(contextapi);

  const [loading, setloading] = React.useState(true);
  const [submitloading, setsubmitloading] = React.useState(false);
  const [total, settotal] = React.useState(0);
  const [cototal, setcototal] = React.useState(0);
  const [lists, setlists] = React.useState([]);
  const [prog, setprog] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_programme/registration");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { check, carryovers, programme } = req.data;
        const modules = check?.map((v) => ({ ...v, type: "Fresh" }));
        const cos = carryovers?.map((v) => ({ ...v, type: "Carryover" }));
        setprog(programme);

        const comb = [...cos, ...modules];
        setlists(comb);
        const sum = check?.map((v) => v.credit).reduce((a, b) => a + b, 0);
        settotal(sum);
        const tco = comb
          ?.map((v) => {
            if (v.type === "Fresh") {
              return 0;
            }
            return 1;
          })
          .reduce((a, b) => a + b, 0);
        setcototal(tco);
        setloading(false);
      } catch ({ message }) {
        toastr.warn(message);
        setloading(false);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _register = async () => {
    setsubmitloading(true);
    const { selectedModules } = context.values;
    if (is_null(selectedModules) || !selectedModules.length) {
      swal("error", "Sorry, you need to select a module", setsubmitloading, false);
      return false;
    }

    if (cototal > 0) {
      //check if the student has registered any fresh module and has carryover
      const co = selectedModules?.map((v) => (v.types === "Carryover" ? 1 : 0)).reduce((a, b) => a + b, 0);
      if (co !== cototal) {
        swal(
          "warning",
          `You have to register all your carryovers before registering fresh modules<br/>${co} carryover(s) selected out of ${cototal}`,
          setsubmitloading,
          false
        );
        return false;
      }
    }
    const { minunit, maxunit } = prog;
    const unit = selectedModules?.map((v) => v.credit).reduce((a, b) => a + b, 0);
    //check if student still have space to add more modules
    if (unit < minunit) {
      swal("info", `Your selected unit have not reach the minimum required units, selected unit ${unit} and minimum required unit ${minunit}`);
    }
    if (unit > maxunit) {
      swal("info", `Your selected unit have exceed the maximum required units, selected unit ${unit} and maximum required unit ${minunit}`);
    }
    if (lists.length !== selectedModules.length && unit < maxunit) {
      const confirms = await Swal.fire({
        icon: "info",
        title: `Module Registration`,
        html: `The system has detected you still have space to add more module(s) <br />
         if you wish to add more modules click cancel button else click save button<br/>
         Minimum unit allowed: <b>${minunit}</b>
         <br/> Maximum unit allowed: <b>${maxunit}</b>
         <br/> Available registration unit <b>${total}</b>
         <br/> Your selected unit <b>${unit}</b><br />
         Unit space remaining <b>${maxunit - unit}</b><br />
         <b>Please note</b> if you decides to submit this, you will have to come to the university to be able to add more modules`,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: `Cancel`,
      });
      if (confirms.isDismissed) {
        setsubmitloading(false);
        return;
      }
    }
    const req = await axiospost("ms_course_registration/register", {
      modules: selectedModules,
    });
    if (req.status !== 200) {
      throw new Error("Sorry, something went wrong, please contact your head of department");
    }
    const { type, message } = req.data;
    if (type === "success") {
      swal(type, message, setsubmitloading, true);
      return;
    }
    swal(type, message, setsubmitloading, false);
    return;
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="my-2">
                <b>PLEASE NOTE:</b> If you have any carryover you are expected to add it before adding any fresh modules, please not that the software
                will not allow you to submit while you have a pending carryover
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-hover table-bordered" id="example">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Credit</th>
                      <th>Level</th>
                      <th>Semester</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Register</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lists?.map((v, i) => (
                      <tr key={v._id}>
                        <th>{i + 1}</th>
                        <td>{v.code}</td>
                        <td>{v.name}</td>
                        <td>{v.credit}</td>
                        <td>{v.level}</td>
                        <td>{v.semester}</td>
                        <td>{v.moduletype}</td>
                        <td>{v.type}</td>
                        <td className="text-center">
                          <RegisteredCourse modules={v._id} types={v.type} credit={v.credit} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th> </th>
                      <th> Total</th>
                      <th>{total}</th>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="my-2">
                {submitloading ? (
                  <button type="button" className="btn btn-primary btn-lg btn-block">
                    Please wait...
                  </button>
                ) : (
                  <button onClick={_register} type="button" className="btn btn-primary btn-lg btn-block">
                    Register Modules
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;

import React from "react";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";

const RegisteredCourse = ({ modules, types, credit }) => {
  const context = React.useContext(contextapi);
  const ref = React.useRef();
  const { selectedModules } = context.values;
  const _selected = () => {
    if (ref.current.checked) {
      let entries;
      if (!is_null(selectedModules)) {
        entries = [...selectedModules, { modules, types, credit }];
      } else {
        entries = [{ modules, types, credit }];
      }
      context.setvalues({ ...context.values, selectedModules: entries });
    } else {
      const entries = selectedModules?.filter((v) => v.modules !== modules);
      context.setvalues({ ...context.values, selectedModules: entries });
    }
  };
  return (
    <div className="form-group my-1">
      <div className="form-check form-check-flat form-check-primary">
        <label className="form-check-label">
          <input
            ref={ref}
            type="checkbox"
            className="form-check-input"
            onChange={_selected}
          />
          <i className="input-helper"></i>
        </label>
      </div>
    </div>
  );
};

export default RegisteredCourse;

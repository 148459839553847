import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const Login = () => {
  const [loading, setloading] = React.useState(false);
  const username = React.useRef();
  const password = React.useRef();
  const context = React.useContext(contextapi);
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.clear();
    context.setvalues([]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _login = async () => {
    try {
      setloading(true);
      const uname = username.current.value;
      const upass = password.current.value;
      if (is_null(uname) || is_null(upass)) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_students/login", {
        emailaddress: uname,
        password: upass,
      });
      if (req.status !== 200 || !is_null(req.data.error)) {
        if (is_null(req.data.error))
          throw new Error(
            "Sorry, system could not finish processing your request"
          );
        else throw new Error(req.data.message);
      }

      const { data } = req;
      if (data.token) {
        localStorage.setItem("isStudentAuth", data.token);
        localStorage.setItem("isStudent", JSON.stringify(data.user));
        context.setvalues({ ...context.values, user: data.user });
        if (!data?.user?.ispasswordchanged) {
          navigate("/change");
          return;
        }

        if (data?.user?.status === 0) {
          navigate("/new-fee");
          return;
        } else {
          navigate("/dashboard");
          return;
        }
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("warning", message, setloading, false);
    }
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-8 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img src="images/logo.jpg" alt="logo" />
                  <img
                    src="images/buk.jpeg"
                    style={{ maxWidth: 100 }}
                    alt="BUK logo"
                  />
                </div>
                <div className="text-center">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h6 className="font-weight-light">Student Portal</h6>
                  <h6 className="font-weight-light">Sign in to continue</h6>
                </div>
                <div className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Username"
                      ref={username}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      ref={password}
                    />
                  </div>
                  <div className="mt-3">
                    {loading ? (
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                        Please wait...
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={_login}
                      >
                        Sign In
                      </button>
                    )}
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-end">
                    <a href="/forgot" className="auth-link text-black">
                      Forgot password?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

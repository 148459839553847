import { localRequest, appHost } from "./uri";
import axios from "axios";
import is_null from "./is_null";

export const axiosget = async (requestLink, Payload, fullHeader) => {
  try {
    const isAuthenticated = localStorage.getItem("isStudentAuth");
    let header = {};
    if (!is_null(isAuthenticated)) {
      header = {
        headers: {
          authorization: "Bearer " + isAuthenticated,
        },
        body: Payload,
      };
    }
    if (!is_null(fullHeader)) {
      header = fullHeader;
    }
    const url = localRequest + requestLink;
    const req = await axios.get(url, header);
    return req;
  } catch (ex) {
    const full = { ...ex };
    if (full.response !== undefined) {
      const { message } = full.response.data;
      if (message !== undefined && message.toString().includes("expired")) {
        localStorage.removeItem("isStudentAuth");
        window.location.href = appHost;
      } else {
        return { type: "error", message };
      }
    } else {
      const { message } = full;
      return { type: "error", error: message };
    }
  }
};

export const axiospost = async (requestLink, PayLoad, fullHeader) => {
  try {
    const isAuthenticated = localStorage.getItem("isStudentAuth");
    let header = {};
    if (isAuthenticated !== undefined && isAuthenticated !== null) {
      header = {
        headers: {
          authorization: "Bearer " + isAuthenticated,
        },
      };
    } else {
      if (fullHeader !== undefined && fullHeader !== null) {
        header = fullHeader;
      }
    }
    const url = localRequest + requestLink;

    const req = await axios.post(url, PayLoad, header);

    return req;
  } catch (ex) {
    const full = { ...ex };
    if (full.response !== undefined) {
      const { error } = full.response.data;
      if (error !== undefined && error.toString().includes("expired")) {
        localStorage.removeItem("isAuthenticated");
        localStorage.setItem("isStudentActivated", undefined);
        window.location.href = appHost;
      } else {
        return { type: "error", error };
      }
    } else {
      const { message } = full;
      return { type: "error", error: message };
    }
  }
};

export const axiosput = async (requestLink, PayLoad, fullHeader) => {
  try {
    const isAuthenticated = localStorage.getItem("isStudentAuth");
    let header = {};
    if (isAuthenticated !== undefined && isAuthenticated !== null) {
      header = {
        headers: {
          authorization: "Bearer " + isAuthenticated,
        },
      };
    } else {
      if (fullHeader !== undefined && fullHeader !== null) {
        header = fullHeader;
      }
    }
    if (isAuthenticated === undefined)
      throw new Error(
        "Sorry you need to be signed in to perform this transaction"
      );
    const url = localRequest + requestLink;

    const req = await axios.put(url, PayLoad, header);
    return req;
  } catch (ex) {
    const full = { ...ex };
    if (full.response !== undefined) {
      const { error } = full.response.data;
      if (error !== undefined && error.toString().includes("expired")) {
        localStorage.removeItem("isAuthenticated");
        localStorage.setItem("isStudentActivated", undefined);
        window.location.href = appHost;
      } else {
        return { type: "error", error };
      }
    } else {
      const { message } = full;
      return { type: "error", error: message };
    }
  }
};

export const axiosdelete = async (requestLink, PayLoad, fullHeader) => {
  try {
    const isAuthenticated = localStorage.getItem("isStudentAuth");
    let header = {};
    if (isAuthenticated !== undefined && isAuthenticated !== null) {
      header = {
        headers: {
          authorization: "Bearer " + isAuthenticated,
          body: PayLoad,
        },
      };
    } else {
      if (fullHeader !== undefined && fullHeader !== null) {
        header = fullHeader;
      }
    }
    const url = localRequest + requestLink;

    const req = await axios.delete(url, header);
    return req;
  } catch (ex) {
    const full = { ...ex };
    if (full.response !== undefined) {
      const { error } = full.response.data;
      if (error !== undefined && error.toString().includes("expired")) {
        localStorage.removeItem("isAuthenticated");
        localStorage.setItem("isStudentActivated", undefined);
        window.location.href = appHost;
      } else {
        return { type: "error", error };
      }
    } else {
      const { message } = full;
      return { type: "error", error: message };
    }
  }
};

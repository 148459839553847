import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";

const MainRegisteredCourses = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_course/student");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">My Registered Modules</p>
                  {loading ? (
                    <div className="text-center">
                      <Loader
                        message={"Please wait while we load your modules"}
                      />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table
                            id="example"
                            className="display expandable-table w-100 table table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Title</th>
                                <th>Credit</th>
                                <th>Type</th>
                                <th>Level</th>
                                <th>Semester</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((v, i) => (
                                <tr>
                                  <th>{i + 1}</th>
                                  <td>{v.courseinfo?.code}</td>
                                  <td>{v.courseinfo?.name}</td>
                                  <td>{v.courseinfo?.credit}</td>
                                  <td>{v?.isfresh ? "Fresh" : "Carryover"}</td>
                                  <td>{v.level}</td>
                                  <td>{v.semester}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainRegisteredCourses;

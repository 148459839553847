import React from "react";

const LetterHeader = () => {
  return (
    <div className="resultHeader">
      <div>
        <img src="../images/logo.jpg" alt="COE KANGERE [UG]" />
      </div>
      <div>
        <div className="text-uppercase school-name p-1">
          ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE
        </div>
        <div className="p-1">
          Main Campus Location Along Bauchi-Gombe Road, Bauchi State
        </div>
        <div className="p-1">
          info@coekangere.edu.ng | finance@coekangere.edu.ng |
          support@coekangere.edu.ng
        </div>
        <div className="p-1">0805-012-1234</div>
      </div>
    </div>
  );
};

export default LetterHeader;

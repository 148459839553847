import React from "react";
import Navbar from "../../components/Navbar";
import MainDashboard from "../../MainContent/MainDashboard";

const Dashboard = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MainDashboard />
      </div>
    </div>
  );
};

export default Dashboard;
